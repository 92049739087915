import React from 'react'

const AccountPayment = () => {
  return (
    <>
      <h2 className="font-opensans-bold fs-44 txt-gray-900 border-0 border-bottom border-gray-200 pb-2">
        Payment Methods <em className="fs-16">fake data</em>
      </h2>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png"
          className="payment_img"
        />
        <span className="fontSFPro-bold fs-18 txt-gray-900">**** 1303</span>
        <span className="fontSFPro-regular fs-18 txt-gray-900">exp: 03/26</span>
        <span className="fontSFPro-regular fs-18 txt-blue-light">
          change <span className="ms-2">remove</span>
        </span>
        <span className="fontSFPro-bold fs-18 txt-gray-900">default</span>
      </div>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <img
          src="https://s1.q4cdn.com/692158879/files/images/brand_imagery/AXP_BlueBoxLogo_Alternate_REGULARscale_RGB_DIGITAL_700x700.png"
          className="payment_img"
        />
        <span className="fontSFPro-bold fs-18 txt-gray-900">**** 7550</span>
        <span className="fontSFPro-regular fs-18 txt-gray-900">exp. 12/21</span>
        <span className="fontSFPro-regular fs-18 txt-blue-light">
          change <span className="ms-2">remove</span>
        </span>
        <span className="fontSFPro-bold fs-18 txt-gray-900">default</span>
      </div>
    </>
  )
}
export default AccountPayment
