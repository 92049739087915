import React, { useEffect, useState } from 'react'
import Loader from '~/components/Loader'
import { REQ_STATUS } from '~/config'
import { Modal, Dropdown } from 'react-bootstrap'
import { useSubscription } from '@chordcommerce/gatsby-theme-autonomy'

const chordIntervals = [
  { id: 1, name: 'Refill every 1 month', intervalLength: 1, intervalUnits: 'month', interval: '1 month' },
  { id: 2, name: 'Refill every 2 months', intervalLength: 2, intervalUnits: 'month', interval: '2 months' },
  { id: 3, name: 'Refill every 3 months', intervalLength: 3, intervalUnits: 'month', interval: '3 months' }
]

const SubscriptionModal = ({ currentSubscription, modalIsOpen, handleModalIsOpen }) => {
  const { cancelSubscription, updateSubscription, loadSubscription, subscription } = useSubscription(
    currentSubscription.id
  )
  const [isOpen, setIsOpen] = useState(false)
  const [selectedInterval, setSelectedInterval] = useState(null)

  useEffect(() => {
    return () => {
      setSelectedInterval(null)
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    const interval = chordIntervals.find(
      item =>
        currentSubscription.intervalLength === item.intervalLength &&
        currentSubscription.intervalUnits === item.intervalUnits
    )
    setSelectedInterval(interval !== undefined ? interval : null)
  }, [currentSubscription])

  useEffect(() => {
    setIsOpen(modalIsOpen)
    return () => {}
  }, [modalIsOpen])

  const handleClose = () => {
    handleModalIsOpen(false)
  }

  const cancel = () => {
    cancelSubscription(currentSubscription.id)
    handleClose()
  }

  const update = async () => {
    if (selectedInterval) {
      try {
        let response = await updateSubscription({
          interval_units: selectedInterval.intervalUnits,
          interval_length: selectedInterval.intervalLength
        })
        handleClose()
        loadSubscription()
      } catch (error) {}
    }
  }

  const changeInterval = interval => {
    setSelectedInterval(interval)
  }

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      contentClassName="custom-modal p-6"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Body className="p-0 font-opensans-400">
        <h2 className="fs-44 txt-gray-900">
          {currentSubscription.lineItems.map((item, idx) => {
            return item.productName + (idx > 0 ? ', ' : '')
          })}
        </h2>
        <div className="border-gray-200 px-4 py-3 border fs-18 txt-gray-900">
          <div className="row">
            <div className="col-12">
              <p>Current subscription saves you X% over the one-time price.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 d-flex">
              <Dropdown className="shop-filter">
                <Dropdown.Toggle variant={null} id="dropdown-product-type">
                  {selectedInterval?.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {chordIntervals.map(item => {
                    return (
                      <Dropdown.Item
                        onClick={() => changeInterval(item)}
                        className="text-uppercase text-blue-light fw-bold"
                        key={item.id}
                      >
                        {item.name}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-auto d-flex align-items-center">
              Your subscription will be renewed on {currentSubscription.actionableDate}
            </div>
          </div>
          <div className="row d-flex mt-3">
            <div className="col-md-4">
              <button onClick={() => update()} className="btn btn-primary py-3 fs-18 fw-bold rounded-0 w-100">
                UPDATE
              </button>
            </div>
            <div className="col-auto d-flex align-items-center">
              <p className="m-0 ps-md-3 c-pointer txt-blue-light fw-bold fs-20" onClick={() => cancel()}>
                Cancel subscription
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const AccountSubscriptions = ({ loadingStatus, subscriptions }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [currentSubscription, setCurrentSubscription] = useState(null)

  useEffect(() => {
    console.log('ssssssssss', subscriptions)
    return () => {}
  }, [subscriptions])

  const viewSubscription = subscription => {
    setCurrentSubscription(subscription)
    setModalOpen(true)
  }

  return (
    <>
      <h2 className="font-opensans-bold fs-44 txt-gray-900 border-0 border-bottom border-gray-200 pb-2">
        Your Subscriptions
      </h2>
      {loadingStatus === REQ_STATUS.PENDING ? (
        <div className="mt-5">
          <Loader size="sm" />
        </div>
      ) : (
        <>
          {subscriptions.length > 0 &&
            subscriptions.map((subscription, key) => (
              <div className="d-flex justify-content-between flex-wrap my-4" key={subscription + key}>
                <div className="d-flex">
                  {/*<img className="item_img me-2" src={subscription.productImg} />*/}
                  <div className="d-flex flex-column justify-content-space-evenly">
                    <span className="fontSFPro-bold fs-18 txt-gray-900">
                      {subscription.lineItems.map((item, idx) => {
                        return item.productName + (idx > 0 ? ', ' : '')
                      })}
                    </span>
                    <span className="fontSFPro-regular fs-16 txt-gray-900 pt-2">
                      <strong>SUBSCRIPTION:</strong> renews every {subscription.interval}
                      {subscription.state === 'canceled' && <span className="text-danger"> - CANCELED</span>}
                    </span>
                    <span className="fontSFPro-regular fs-18 txt-blue-light text-decoration-underline c-pointer pt-2">
                      <span onClick={() => viewSubscription(subscription)}>
                        change{subscription.state === 'canceled' ? '' : '/cancel'}
                      </span>
                    </span>
                  </div>
                </div>
                <div>{/*<span className="fontSFPro-regular fs-20 txt-gray-900 me-3">$price/month</span>*/}</div>
              </div>
            ))}
        </>
      )}

      {currentSubscription !== null && (
        <SubscriptionModal
          currentSubscription={currentSubscription}
          modalIsOpen={modalOpen}
          handleModalIsOpen={() => setModalOpen(false)}
        />
      )}
    </>
  )
}
export default AccountSubscriptions
