import React, { useEffect, useState } from 'react'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'

const ordersData = [
  {
    id: 21332,
    date: '10/28/2021',
    orderItem: 'Fake User',
    email: 'u.fake@example.com',
    value: '200 MG',
    img: 'https://d1dglrf9v97u7a.cloudfront.net/commons/images/attachments/000/000/148/small/Day_Gummies_2.jpeg?1643813874',
    products: [
      { id: 164543, name: 'Nighttime Gummies', amount: 65 },
      { id: 123353, name: 'Anytime Gummies (subscription)', amount: 28 }
    ],
    tax: 1.75
  },
  {
    id: 321432,
    date: '10/26/2021',
    orderItem: 'Example USer',
    img: 'https://d1dglrf9v97u7a.cloudfront.net/commons/images/attachments/000/000/150/small/Anytime_Gummies.png?1643813910',
    value: '30 CT./10 MG',
    email: 'fake@example.com',
    products: [
      { id: 875, name: 'Nighttime Gummies', amount: 20 },
      { id: 9643, name: 'Anytime Gummies (subscription)', amount: 54 }
    ],
    tax: 1.75
  },
  {
    id: 43214331254,
    date: '09/01/2021',
    orderItem: 'Fake McKinney',
    img: 'https://d1dglrf9v97u7a.cloudfront.net/commons/images/attachments/000/000/148/small/Day_Gummies_2.jpeg?1643813874',
    value: '200 MG',
    email: 'm.fake.email@exmaple.com',
    products: [
      { id: 1576, name: 'Nighttime Gummies', amount: 23 },
      { id: 4567, name: 'Anytime Gummies (subscription)', amount: 67 }
    ],
    tax: 1.75
  },
  {
    id: 97834,
    date: '11/08/2021',
    orderItem: 'Fake Customer',
    img: 'https://d1dglrf9v97u7a.cloudfront.net/commons/images/attachments/000/000/150/small/Anytime_Gummies.png?1643813910',
    value: '30 CT./10 MG',
    email: 'fake.customer@exmaple.com',
    products: [
      { id: 4353, name: 'Nighttime Gummies', amount: 42 },
      { id: 1235, name: 'Anytime Gummies (subscription)', amount: 50 }
    ],
    tax: 1.75
  }
]
const AccountOrders = () => {
  const [orders, setOrders] = useState([])
  const [detailedOrder, setDetailedOrder] = useState(null)

  useEffect(() => {
    setOrders(ordersData)
  }, [])
  return (
    <>
      <h2 className="font-opensans-bold fs-44 txt-gray-900 border-0 border-bottom border-gray-200 pb-2">Your Orders</h2>
      <div className="row mt-2 ms-2">
        <div className="col-12 px-0">
          <table className="table table-custom">
            <thead>
              <tr>
                <th scope="col" className="fontSFPro-bold fs-21 txt-gray-900 ps-0">
                  Order Date
                </th>
                <th scope="col" className="d-none d-sm-block fontSFPro-bold fs-21 txt-gray-900">
                  Item
                </th>
                <th scope="col" className="fontSFPro-bold fs-21 txt-gray-900 text-end">
                  Order Total
                </th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {orders.map((order, idx) => {
                let totalOrder = 0
                order.products.map(product => {
                  totalOrder = totalOrder + product.amount
                })
                let totalWithTax = totalOrder + order.tax
                return (
                  <tr key={order.id + idx}>
                    <td className="ps-1">
                      <span className="order_date fontSFPro-regular fs-21 txt-gray-900">{order.date}</span>
                      <div className="d-flex flex-column d-sm-none">
                        <p className="m-0 order_item  fontSFPro-regular fs-21 txt-gray-900">{order.orderItem}</p>
                        {detailedOrder && detailedOrder.id === order.id && (
                          <div className="fs-18">
                            <span className="fs-16 text-blue-light customer_email">{order.email}</span>
                            <div className="d-flex flex-column mt-2">
                              {order.products.map((product, index) => {
                                return (
                                  <span className="product_elements" key={product.id + index}>
                                    {product.name}
                                  </span>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="d-none d-sm-block border-0">
                      <div className="d-flex flex-column">
                        {detailedOrder && detailedOrder.id === order.id ? (
                          <div className="fs-18 d-flex">
                            <img className="item_img me-2" src={order.img} />
                            <div className="d-flex flex-column">
                              <span className="order_date fontSFPro-regular fs-21 txt-gray-900">{order.orderItem}</span>
                              <span className="order_date fontSFPro-regular fs-16 txt-gray-900">{order.value}</span>
                            </div>
                          </div>
                        ) : (
                          <p className="m-0">{order.orderItem}</p>
                        )}
                      </div>
                    </td>
                    <td className=" custom-cell text-end">
                      <div className="d-flex flex-column justify-content-between custom-cell-wrapper w-100 pe-5">
                        <strong className="fontSFPro-bold fs-21 txt-gray-900">${totalOrder}</strong>
                        {detailedOrder?.id === order.id && (
                          <div className="d-flex flex-column mt-2">
                            <span className="fontSFPro-regular fs-21 txt-gray-900">
                              tax:<span className="fontSFPro-bold fs-21 txt-gray-900 ms-3">${order.tax}</span>
                            </span>
                            <span className="fontSFPro-regular fs-21 txt-gray-900">
                              total:<span className="fontSFPro-bold fs-21 txt-gray-900 ms-3">${totalWithTax}</span>
                            </span>
                            <span className="fontSFPro-bold fs-18 txt-gray-900">CHARGED TO VISA: **** 1303</span>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="d-inline-block border-bottom-0 fs-18 text-end w-100 pt-0">
                      <a
                        className="text-blue-light c-pointer"
                        onClick={() => setDetailedOrder(detailedOrder?.id === order.id ? null : order)}
                      >
                        <span className="d-none d-sm-block">
                          {detailedOrder?.id === order.id ? 'Hide' : 'View'} Details
                        </span>
                        <span className="mobile-arrow d-sm-none">
                          {detailedOrder?.id === order.id ? <FaAngleUp /> : <FaAngleDown />}
                        </span>
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default AccountOrders
