import React, { useState, useEffect } from 'react'
import Layout from '~/components/Layout'
import { Col, Row } from 'react-bootstrap'
import Metadata from '~/components/Metadata'
import AccountSubscriptions from '~/components/Account/AccountSubscriptions'
import AccountPayment from '~/components/Account/AccountPayment'
import AccountOrders from '~/components/Account/AccountOrders'
import { useSubscriptions } from '@chordcommerce/gatsby-theme-autonomy'
import ChordServices from '~/api/chord-services'
import { useSubscription } from '@chordcommerce/gatsby-theme-autonomy'
import { REQ_STATUS } from '~/config'

export default function AccountPage() {
  const subscriptions = useSubscriptions()
  const [chordProducts, setChordProducts] = useState([])
  const { cancelSubscription } = useSubscription()
  const [updateSubscriptionState, setUpdateSubscriptionState] = useState(false)
  const [subscriptionsReqStatus, setSubscriptionsReqStatus] = useState(REQ_STATUS.IDLE)

  useEffect(() => {
    ChordServices.allProducts()
      .then(result => {
        setChordProducts(result.data.products)
      })
      .catch(() => {
        console.error('error fetching products')
      })
    return () => {
      setChordProducts([])
    }
  }, [])

  useEffect(() => {
    if (subscriptions.length > 0 && chordProducts.length > 0) {
      setSubscriptionsReqStatus(REQ_STATUS.PENDING)
      subscriptions.map(subscription => {
        return subscription.lineItems.map(item => {
          const product = chordProducts.find(product => product.master.sku === item.sku)
          if (product !== undefined) {
            item.productName = product.name
          }
        })
      })
      setSubscriptionsReqStatus(REQ_STATUS.RESOLVED)
    }
  }, [subscriptions, chordProducts])

  return (
    <Layout>
      <Metadata title="Cart" />
      <>
        <h1 className="font-opensans-300 fs-72 txt-gray-900 mb-5">Manage Your Account</h1>
        <Row>
          <Col className="pe-5">
            <AccountSubscriptions loadingStatus={subscriptionsReqStatus} subscriptions={subscriptions} />
          </Col>
          <Col className="pe-5">
            <AccountPayment />
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <AccountOrders />
          </Col>
        </Row>
      </>
    </Layout>
  )
}
